<template>
  <div class="box sc-width">
    <div class="main-title-box">
      <h1 class="main-title">Exchanged Business Cards</h1>
      <!-- <div v-if="role === 'attendee'" class="btn clearbtn2" @click="addIt">
        Inquire Sourcing
      </div> -->
    </div>
    <div class="pd-20">
      <Card dis-hover>
        <Table :loading="loading" :columns="columns" :data="list">
          <template slot-scope="{ row: item }" slot="chat">
            <Icon
              class="chat-icon"
              @click="$router.push(`/chat?company=${item.id}`)"
              type="ios-chatbubbles-outline"
            />
          </template>
        </Table>

        <div class="page">
          <Page
            :page-size="limit"
            :page-size-opts="[5, 10, 15, 20]"
            :total="total"
            show-sizer
            show-elevator
            show-total
            @on-change="pageChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryBusinessCardCompanyList } = api;

export default {
  created() {
    this.queryBusinessCardCompanyList();
  },

  data() {
    return {
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,

      filter: {
        state: ""
      },

      columns: [
        {
          title: "COMPANY",
          key: "company_name"
        },
        {
          title: "CHAT",
          width: 150,
          align: "center",
          slot: "chat"
        }
      ]
    };
  },

  methods: {
    queryBusinessCardCompanyList() {
      this.loading = true;

      _queryBusinessCardCompanyList({
        page: this.page,
        limit: this.limit
      })
        .then(response => {
          this.loading = false;
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    pageChange(page) {
      this.page = page;
      this.queryBusinessCardCompanyList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryBusinessCardCompanyList();
    }
  }
};
</script>

<style lang="less" scoped>
.sc-width {
  background: #ffffff;
  margin: 206px auto 0 auto;
}
.main-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}

.search-box {
  display: flex;
  align-items: center;
  /*border-radius: 50px;*/
  height: 35px;
  overflow: hidden;
  width: 45%;
  margin: 20px;
  .input-box {
    position: relative;
    flex: 1;
    height: 100%;
    background: #fff;
    line-height: initial;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*border: 1px solid #dfdfdf;*/
    /*border-right: none;*/
    /*border-top-left-radius: 40px;*/
    /*border-bottom-left-radius: 40px;*/
    border: 1px solid #ef7f21;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border-radius: 5px;
    /*display: flex;*/
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
    margin-left: 20px;
    background: #ef7f21;
    font-size: 18px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    /*padding-top: 9px;*/
    width: 120px;
    height: 35px;
  }
  .my-sourcing {
    width: 200px;
    height: 30px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #444;
    font-size: 12px;
    text-align: right;
    .des {
      display: block;
    }
    .num {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// /deep/ .list-table .ivu-table-body .ivu-btn {
//   color: @primary-color;
//   border: 1px solid @primary-color;
// }

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .search-input {
    width: 400px;
  }
}

.box {
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cards {
    min-height: 300px;
    position: relative;
    .item {
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 6px;
      background: #fff;
      box-shadow: 3px 3px 6px #ddd;
      .img-box {
        width: 100%;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
        .wrap {
          min-width: 40px;
          height: 40px;
          margin: 15px auto 10px;
          overflow: hidden;
          text-align: center;
          .avatar {
            height: 100%;
          }
        }
        .cname {
          width: 100%;
          text-align: center;
          font-size: 13px;
          color: #444;
          font-weight: bold;
        }
      }

      .name {
        display: flex;
        border-bottom: 1px solid #ccc;
        color: #666;
        .com-name {
          flex: 0 0 50%;
          padding: 10px 0;
          padding-left: 20px;
          font-size: 12px;
          color: #888;
          small {
            color: #000;
          }
        }
        .product-name {
          border-right: 1px solid #ccc;
        }
      }
      .user {
        padding: 10px;
        text-align: center;
        .user-name {
          font-size: 12px;
          color: @primary-color;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .tags {
        padding: 10px;
        height: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .tag {
          color: #fff;
          border-radius: 3px;
          font-size: 12px;
          margin-right: 4px;
          height: 25px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          font-weight: bold;
        }
      }
    }

    .ivu-poptip-body {
      h4 {
        font-size: 12px;
        text-align: left;
        color: #444;
        padding: 4px 0;
      }
      .tags {
        flex-wrap: wrap;
        display: flex;
        p {
          text-align: left;
          color: #999;
          flex: 0 0 50%;
          padding: 4px 0;
        }
      }
    }
  }
}
.chat-icon {
  color: #f57c00;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="less">
.ivu-table thead {
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
}
.ivu-table th {
  background: none;
  font-size: 18px;

  font-weight: 500;
  color: #fefefe;
  line-height: 19px;
}
</style>
